import React from 'react';
import loadable from '@loadable/component';

const FooterV1 = loadable(() => import('./FooterV1'));
const FooterV2 = loadable(() => import('./FooterV2'));

const Footer = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <FooterV2 {...props} />;
    default:
    case 'version1':
      return <FooterV1 {...props} />;
  }
};

export default Footer;
